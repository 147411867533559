import { useStaticQuery, graphql } from 'gatsby'

const useClientsMetadata = () => {
  const query = useStaticQuery(graphql`
    query ClientsMetadata {
      bannerBGDesktop: file(
        relativePath: { eq: "clients/banner/bg-desktop.jpg" }
      ) {
        id
        ...ClientsImageOptimize
      }
      bannerBGTablet: file(
        relativePath: { eq: "clients/banner/bg-tablet.jpg" }
      ) {
        id
        ...ClientsImageOptimize
      }
      bannerBGPhone: file(relativePath: { eq: "clients/banner/bg-phone.jpg" }) {
        id
        ...ClientsImageOptimize
      }
      introBG: file(relativePath: { eq: "clients/intro/bg.jpg" }) {
        id
        ...ClientsImageOptimize
      }
      introBGPhone: file(relativePath: { eq: "clients/intro/bg-phone.jpg" }) {
        id
        ...ClientsImageOptimize
      }
      introDevices: file(relativePath: { eq: "clients/intro/devices.png" }) {
        id
        ...ClientsImageOptimize
      }

      clientsCA: file(relativePath: { eq: "clients/list/ca.jpg" }) {
        id
        ...ClientsImageOptimize
      }
      clientsTX: file(relativePath: { eq: "clients/list/tx.jpg" }) {
        id
        ...ClientsImageOptimize
      }
      clientsNY: file(relativePath: { eq: "clients/list/ny.jpg" }) {
        id
        ...ClientsImageOptimize
      }
      clientsAZ: file(relativePath: { eq: "clients/list/az.jpg" }) {
        id
        ...ClientsImageOptimize
      }
      clientsGA: file(relativePath: { eq: "clients/list/ga.jpg" }) {
        id
        ...ClientsImageOptimize
      }
      clientsFL: file(relativePath: { eq: "clients/list/fl.jpg" }) {
        id
        ...ClientsImageOptimize
      }
    }

    fragment ClientsImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}

export default useClientsMetadata
