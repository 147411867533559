import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import * as cx from './Intro.module.scss'

const Intro = ({
  wrapperClassName,
  bg,
  heading,
  subheading,
  paragraph,
  devicesImg,
  alt,
  headingClassName = 'heading-2',
  subheadingClassName = 'subtitle-1',
  paragraphClassName = 'subtitle-5',
}) => {
  return (
    <section
      className={clsx([cx.wrapper], wrapperClassName)}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className={cx.gradient} />
      <div className={cx.container}>
        <h2 className={headingClassName}>{heading}</h2>
        <h3 className={subheadingClassName}>{subheading}</h3>
        <hr />
        <p className={paragraphClassName}>{paragraph}</p>
        <div className={cx.devices}>
          <ElementImage src={devicesImg} alt={alt} />
        </div>
      </div>
    </section>
  )
}

Intro.propTypes = {
  wrapperClassName: PropTypes.string,
  bg: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  paragraph: PropTypes.string,
  devicesImg: PropTypes.string,
  alt: PropTypes.string,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
  paragraphClassName: PropTypes.string,
}

export default Intro
