import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// CSS
import * as cx from './TrustedByTheBest.module.scss'

// libraries
import parse from 'html-react-parser'

const items = [
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Hilton-_-Hyland.png',
      alt: 'Hilton',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Ogroup.png',
      alt: 'Ogroup',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Joyce-Rey.png',
      alt: 'Joyce',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Altman-Brothers.png',
      alt: 'Altman',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Roh-Habibi.png',
      alt: 'Roh',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Ivan-Sher.png',
      alt: 'Ivan',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Egypt-Sherrod.png',
      alt: 'Egypt',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Quiana-Watson.png',
      alt: 'Quiana',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Christophe-Choo.png',
      alt: 'Christophe',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Eklund-Gomes.png',
      alt: 'Eklund',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Aaron-Kirman.png',
      alt: 'Aaron',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Jills-Zeder.png',
      alt: 'Jills',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Sally-Forster-Jones.png',
      alt: 'Sally',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Robert-Slack.png',
      alt: 'Robert',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Khrista-Jarvis-Team.png',
      alt: 'Khrista',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Louise-Phillips-Forbes-Team.png',
      alt: 'Louise',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Globl-Red_Kofi-Nartey.png',
      alt: 'Globl',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Anne-Hogan-Perry.png',
      alt: 'Anne',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Josh-Flagg.png',
      alt: 'Josh',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Williams_Williams.png',
      alt: 'Williams',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Dolly-Lenz.png',
      alt: 'Dolly',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Tracy-Tutor.png',
      alt: 'Tracy',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Gary-Gold.png',
      alt: 'Gary',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Daftarian-Group.png',
      alt: 'Daftarian',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Jason-Mitchell.png',
      alt: 'Jason',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Valery-Neuman.png',
      alt: 'Valery',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/Sue-Adler.png',
      alt: 'Sue',
    },
  },
  {
    logo: {
      url:
        'https://resources.agentimage.com/redesign/local/thank-you/logo/The-Caroll-Group.png',
      alt: 'Caroll',
    },
  },
]

const ReviewsTrustedByTheBest = ({
  wrapperClassName,
  alt = false,
  title = 'Trusted By The Best of The Best',
  subtitle = 'We’ve delivered award-winning work for many of the agents you admire. <br />Just take a look at our illustrious list of clients.',
  brands = items,
}) => {
  return (
    <div className={clsx([cx.section], wrapperClassName, { [cx.alt]: alt })}>
      <div className={cx.title}>
        <h2 className="heading-5">{parse(title)}</h2>
        <p className="subtitle-5">{parse(subtitle)}</p>
      </div>

      <div className={cx.item}>
        {brands.map((item, index) => {
          return <img key={index} src={item.logo.url} alt={item.logo.alt} />
        })}
      </div>
    </div>
  )
}

ReviewsTrustedByTheBest.propTypes = {
  wrapperClassName: PropTypes.string,
  alt: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  brands: PropTypes.any,
}

export default ReviewsTrustedByTheBest
