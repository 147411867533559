import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'

import Breadcrumbs from '@components/seo/Breadcrumbs'
import * as cx from './Banner.module.scss'

const Banner = ({
  wrapperClassName,
  bg,
  breadcrumbs,
  heading,
  subheading,
  headingClassName = 'heading-1',
  subheadingClassName = 'subtitle-1',
}) => {
  return (
    <section
      className={clsx([cx.wrapper], wrapperClassName)}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <Breadcrumbs
        wrapperClassName={`${cx.breadcrumbs} global-breadcrumbs`}
        data={breadcrumbs}
      />
      <h1 className={cx.container}>
        <span className={headingClassName}>{parse(heading)}</span>
        <span className={subheadingClassName}>{parse(subheading)}</span>
      </h1>
    </section>
  )
}

Banner.propTypes = {
  wrapperClassName: PropTypes.string,
  bg: PropTypes.string,
  breadcrumbs: PropTypes.array,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
}

export default Banner
