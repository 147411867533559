import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import { Button } from '@components/global/buttons/Buttons'
import SvgArrowAlt from '@svg/arrowAlt.inline'
import useWindowSize from '@hooks/useWindowSize'
import useTop10CardsMetadata from '@hooks/top-10-cards-metadata'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './Top10Cards.module.scss'

const Top10Card = ({
  bg,
  label,
  img,
  buttonText = 'View the list',
  buttonLink,
  labelClassName = 'subtitle-9',
}) => {
  return (
    <li
      className={cx.card}
      style={{ backgroundImage: `url(${extractImage(bg)})` }}
    >
      <h5 className={labelClassName}>{parse(label)}</h5>
      <Link to={buttonLink}>
        <Button ghost text={buttonText} svg={<SvgArrowAlt />} />
      </Link>

      <div className={cx.img}>
        <ElementImage src={img} alt={label} />
      </div>
    </li>
  )
}

const Top10Cards = ({
  wrapperClassName,
  heading = 'CHECK OUT<br/> THESE TOP 10 LISTS!',
  subheading = 'WANT MORE INSPIRATION?',
  headingClassName = 'heading-4',
  subheadingClassName = 'subtitle-1',
  accentClassName = 'bg-accent-300',
  minHeight = { desktop: 606, tablet: 596, phone: 710 },
  items = [
    {
      label: '10 Best Real Estate<br/> Video Websites',
      link: '/real-estate-websites/10-best-real-estate-video-websites/',
    },
    {
      label: '10 Best Real Estate<br/> Mobile Websites',
      link: '/real-estate-websites/10-best-mobile-websites/',
    },
    {
      label: '10 Best International<br/> Real Estate Websites',
      link: '/real-estate-websites/10-best-international-real-estate-websites/',
    },
    {
      label: '10 Best Agent Image X Websites',
      link: '/real-estate-websites/10-best-agent-image-x-websites/',
    },
    {
      label: '10 Best<br/> Agent Pro Websites',
      link: '/real-estate-websites/10-best-agent-pro-websites/',
    },
    {
      label: '10 Best Semi-Custom Websites',
      link: '/real-estate-websites/10-best-semi-custom-websites/',
    },
    {
      label: '10 Best Imagine Studio Websites',
      link: '/real-estate-websites/10-best-imagine-studio-websites/',
    },
    {
      label: '10 Best True Custom Websites',
      link: '/real-estate-websites/10-best-true-custom-websites/',
    },
  ],
  imgItems,
}) => {
  const {
    top10BG,
    top10Video,
    top10Mobile,
    top10International,
    top10Aix,
    top10AgentPro,
    top10SemiCustom,
    top10ImagineStudio,
    top10TrueCustom,
  } = useTop10CardsMetadata()
  const defaultImgItems = [
    top10Video,
    top10Mobile,
    top10International,
    top10Aix,
    top10AgentPro,
    top10SemiCustom,
    top10ImagineStudio,
    top10TrueCustom,
  ]
  const isDesktopLarge = useWindowSize().width >= 1280
  const isPhone = useWindowSize().width <= 767
  const appliedImgItems = imgItems || defaultImgItems
  const minHeightContent = isDesktopLarge
    ? minHeight?.desktop
    : isPhone
    ? minHeight?.phone
    : minHeight?.tablet
  const [isLoadMore, setIsLoadMore] = useState(false)
  const [maxHeightContent, setMaxHeightContent] = useState(
    `${minHeightContent}px`
  )
  const contentRef = useRef(null)
  const toggleAccordion = (e) => {
    setIsLoadMore((isLoadMore) => !isLoadMore)
    setMaxHeightContent(
      isLoadMore
        ? `${minHeightContent}px`
        : `${contentRef.current.scrollHeight}px`
    )
  }
  useEffect(() => {
    if (isLoadMore) return
    setMaxHeightContent(`${minHeightContent}px`)
  }, [maxHeightContent, isPhone, isDesktopLarge])

  return (
    <section className={clsx([cx.wrapper], wrapperClassName)}>
      <div className={clsx([cx.accent], accentClassName)} />
      <h4 className={cx.header}>
        <span className={subheadingClassName}>{subheading}</span>
        <span className={headingClassName}>{parse(heading)}</span>
      </h4>

      <div
        ref={contentRef}
        className={clsx([cx.content], { [cx.load]: isLoadMore })}
        style={{
          maxHeight: `${maxHeightContent}`,
          minHeight: `${minHeightContent}px`,
        }}
      >
        <ul className={cx.list}>
          {items?.map((item, key) => (
            <Top10Card
              key={key}
              bg={top10BG}
              label={item.label}
              buttonLink={item.link}
              img={extractImage(appliedImgItems[key])}
              imgAlt={item.imgAlt}
            />
          ))}
        </ul>

        <Button
          ghost
          wrapperClassName={cx.btnLess}
          text="View less"
          onClick={toggleAccordion}
        />
      </div>
      <Button
        ghost
        wrapperClassName={clsx([cx.btn], { [cx.load]: isLoadMore })}
        text="Load more"
        onClick={toggleAccordion}
      />
    </section>
  )
}

Top10Cards.propTypes = {
  wrapperClassName: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
  accentClassName: PropTypes.string,
  minHeight: PropTypes.object,
  items: PropTypes.array,
  imgItems: PropTypes.array,
}

export default Top10Cards
