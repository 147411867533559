// extracted by mini-css-extract-plugin
export var active = "ClientsAccordion-module--active--712e2";
export var btn = "ClientsAccordion-module--btn--afe6b";
export var child = "ClientsAccordion-module--child--b08a0";
export var circle = "ClientsAccordion-module--circle--8ab38";
export var content = "ClientsAccordion-module--content--f0a27";
export var inactive = "ClientsAccordion-module--inactive--571ea";
export var labels = "ClientsAccordion-module--labels--25193";
export var subItem = "ClientsAccordion-module--sub-item--10d9b";
export var svg = "ClientsAccordion-module--svg--65fca";
export var wrapper = "ClientsAccordion-module--wrapper--845ab";