import React from 'react'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import { Banner, Intro, ClientsList } from '@components/pages/clients'
import ReviewsTrustedByTheBest from '@components/global/Reviews/TrustedByTheBest'
import Top10Cards from '@components/global/cards/Top10Cards'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'
import {
  pageData as page,
  bannerData,
  introData,
  listData,
} from '@src/data/Clients'
import useClientsMetadata from '@hooks/clients-metadata'
import useWindowSize from '@hooks/useWindowSize'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './Clients.module.scss'

const Clients = () => {
  const {
    bannerBGDesktop,
    bannerBGTablet,
    bannerBGPhone,
    introDevices,
    introBG,
    introBGPhone,
    clientsCA,
    clientsTX,
    clientsNY,
    clientsAZ,
    clientsGA,
    clientsFL,
  } = useClientsMetadata()
  const isDesktop = useWindowSize().width >= 1024
  const isPhone = useWindowSize().width <= 767
  const clientsFeaturedStatesBG = [
    clientsCA,
    clientsTX,
    clientsNY,
    clientsAZ,
    clientsGA,
    clientsFL,
  ]

  return (
    <LayoutInnerPage hasWhiteTextTransparentNav wrapperClassName={cx.wrapper}>
      <Seo title={page.title} uri={page.uri} />
      <Banner
        breadcrumbs={page.breadcrumbs}
        heading={bannerData.heading}
        subheading={bannerData.subheading}
        bg={extractImage(
          isDesktop ? bannerBGDesktop : isPhone ? bannerBGPhone : bannerBGTablet
        )}
      />
      <Intro
        heading={introData.heading}
        subheading={introData.subheading}
        paragraph={introData.paragraph}
        bg={extractImage(isPhone ? introBGPhone : introBG)}
        devicesImg={extractImage(introDevices)}
        alt={introData.alt}
      />
      <ReviewsTrustedByTheBest
        wrapperClassName={cx.logos}
        alt={true}
        title={page?.thankYou?.brands?.title}
        subtitle={page?.thankYou?.brands?.subtitle}
        brands={page?.thankYou?.brands?.items}
      />
      <ClientsList
        heading={listData.heading}
        description={listData.description}
        featuredStates={listData.featuredStates}
        featuredStatesBG={clientsFeaturedStatesBG}
        items={listData.clients}
      />
      <Top10Cards />
      <LeadContactForm
        version={page.lcf.version}
        modelOrBackground={page.lcf.model}
        title={page.lcf.title}
        subtitle={page.lcf.subtitle}
        submitLabel={page.lcf.submitLabel}
        leadName={page.lcf.leadName}
        layout={page.lcf.layout}
      />
    </LayoutInnerPage>
  )
}

export default Clients
