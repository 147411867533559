import React, { useRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import parse from 'html-react-parser'

import SvgArrowAlt from '@svg/arrowAlt.inline'
import useWindowSize from '@hooks/useWindowSize'
import * as cx from './ClientsAccordion.module.scss'

const ClientDetails = ({
  website,
  city,
  specialization,
  link,
  websiteClassName = 'subtitle-5 strong',
  bodyClassName = 'default-body large',
}) => {
  const isPhone = useWindowSize().width <= 767

  return (
    <>
      {!isPhone && (
        <div className={clsx(cx.subItem)}>
          <div>
            <span className={cx.circle} />
            <span className={websiteClassName}>{parse(website)}</span>
          </div>
          <div>
            <span className={bodyClassName}>{parse(city)}</span>
          </div>
          <div>
            <span className={bodyClassName}>{parse(specialization)}</span>
          </div>
          <div>
            <a
              className="primary-button alt"
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              View
            </a>
          </div>
        </div>
      )}
      {isPhone && (
        <div className={clsx(cx.subItem)}>
          <div>
            <span className={cx.circle} />
            <span className={websiteClassName}>{parse(website)}</span>
            <span className={bodyClassName}>{parse(city)}</span>
          </div>

          <div>
            <a
              className="primary-button alt"
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              View
            </a>
          </div>
        </div>
      )}
    </>
  )
}

const ClientsAccordion = React.forwardRef(
  (
    {
      wrapperClassName,
      state,
      abbr,
      content,
      stateClassName = 'heading-6',
      labelClassName = 'small-heading strong',
      refreshHeight,
      isActive,
      activeAccordionHandler,
    },
    ref
  ) => {
    const contentSpace = useRef(null)
    const labels = ['Website', 'City', 'Specialization', '']

    const toggleAccordion = (e) => {
      activeAccordionHandler()
      refreshHeight()
    }

    useImperativeHandle(ref, () => ({
      toggleAccordion() {
        toggleAccordion()
      },
    }))

    return (
      <li
        id={`clients-state-${state.replace(/\s+/g, '-').toLowerCase()}`}
        className={clsx([cx.wrapper], wrapperClassName, 'accordion-parent', {
          'accordion-parent-active': isActive,
        })}
      >
        <button
          ref={ref}
          type="button"
          className={clsx('accordion-button', [cx.btn], {
            'accordion-active': isActive,
            [cx.active]: isActive,
            [cx.inactive]: content.length === 0,
          })}
          onClick={toggleAccordion}
        >
          <span className={clsx(cx.svg)}>
            <SvgArrowAlt />
          </span>
          <span className={stateClassName}>{state}</span>
          {abbr && <span className={stateClassName}>{`(${abbr})`}</span>}
        </button>
        <div
          ref={contentSpace}
          style={{
            maxHeight: isActive
              ? `${contentSpace?.current?.scrollHeight}px`
              : '0px',
          }}
          className={clsx([cx.child], 'accordion-child')}
        >
          <div className={cx.content}>
            <div className={cx.labels}>
              {labels.map((label, key) => {
                return (
                  <span key={key} className={labelClassName}>
                    {label}
                  </span>
                )
              })}
            </div>
            {content.map((item, key) => {
              return (
                <ClientDetails
                  key={key}
                  website={item.website}
                  city={item.city}
                  specialization={item.specialization}
                  link={item.link}
                />
              )
            })}
          </div>
        </div>
      </li>
    )
  }
)

ClientsAccordion.propTypes = {
  wrapperClassName: PropTypes.string,
  isActive: PropTypes.bool,
  state: PropTypes.string,
  abbr: PropTypes.string,
  content: PropTypes.array,
  stateClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  refreshHeight: PropTypes.func,
  activeAccordionHandler: PropTypes.func,
}

export default ClientsAccordion
