import React, { useState, useRef, useEffect } from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import ClientsAccordion from '@components/global/accordions/ClientsAccordion'
import ElementImage from '@components/global/element/Image'
import { Button } from '@components/global/buttons/Buttons'
import SvgArrowAlt from '@svg/arrowAlt.inline'
import useWindowSize from '@hooks/useWindowSize'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './ClientsList.module.scss'

const StateCard = React.forwardRef(
  (
    {
      state,
      abbr,
      bg,
      stateClassName = 'subtitle-8',
      abbrClassName = 'small-heading strong',
      stateCardHandler,
    },
    ref
  ) => {
    return (
      <button ref={ref} className={cx.card} onClick={stateCardHandler}>
        <div className={cx.bg}>
          <ElementImage src={extractImage(bg)} alt={`${state} background`} />
        </div>

        <div className={cx.container}>
          <div className={cx.content}>
            <span className={abbrClassName}>{abbr}</span>
            <span className={stateClassName}>{state}</span>
          </div>
        </div>
      </button>
    )
  }
)

const ClientsList = ({
  wrapperClassName,
  heading,
  description,
  featuredStates,
  featuredStatesBG,
  items,
  headingClassName = 'heading-5',
  descriptionClassName = 'subtitle-5',
}) => {
  const isDesktop = useWindowSize().width >= 1024
  const isPhone = useWindowSize().width <= 767
  const minHeightContent = isDesktop || isPhone ? 880 : 904
  const [isLoadMore, setIsLoadMore] = useState(false)
  const [activeIndex, setActiveIndex] = useState('0')
  const [maxHeightContent, setMaxHeightContent] = useState(
    `${minHeightContent}px`
  )

  const contentRef = useRef(null)
  const state1Ref = useRef(null)
  const accordion1Ref = useRef(null)
  const state2Ref = useRef(null)
  const accordion2Ref = useRef(null)
  const state3Ref = useRef(null)
  const accordion3Ref = useRef(null)
  const state4Ref = useRef(null)
  const accordion4Ref = useRef(null)
  const state5Ref = useRef(null)
  const accordion5Ref = useRef(null)
  const state6Ref = useRef(null)
  const accordion6Ref = useRef(null)
  const accordionRefs = [
    accordion1Ref,
    accordion2Ref,
    accordion3Ref,
    accordion4Ref,
    accordion5Ref,
    accordion6Ref,
  ]

  const activeAccordionHandler = (index) => {
    if (activeIndex === index) {
      return setActiveIndex('0')
    }
    setActiveIndex(() => index)
  }

  const toggleAccordion = (e) => {
    setIsLoadMore((isLoadMore) => !isLoadMore)
    setMaxHeightContent(
      isLoadMore
        ? `${minHeightContent}px`
        : `${contentRef.current.scrollHeight}px`
    )
  }
  const loadMoreHandler = () => {
    setIsLoadMore(true)
    setMaxHeightContent(`${contentRef.current.scrollHeight}px`)
  }
  const refreshHeight = () => {
    if (!isLoadMore) return
    setTimeout(() => {
      setMaxHeightContent(`${contentRef.current.scrollHeight}px`)
    }, 800)
  }

  const createStateCardHandler = (
    index,
    scrollDelay = 0,
    accordionDelay = 500
  ) => {
    const scrollHeight = isPhone ? 3000 : 2400
    const stateCardHandler = () => {
      if (index === 0 || index === 3) {
        window.scrollTo({
          top: scrollHeight,
          behavior: 'smooth',
        })
      } else {
        loadMoreHandler()

        setTimeout(() => {
          scrollTo(
            `#clients-state-${featuredStates[index]?.state
              .replace(/\s+/g, '-')
              .toLowerCase()}`
          )
        }, scrollDelay)
      }

      setTimeout(() => {
        accordionRefs[index].current.toggleAccordion()
      }, accordionDelay)
    }
    return stateCardHandler
  }
  const state1CardHandler = createStateCardHandler(0)
  const state2CardHandler = createStateCardHandler(1, 500, 1400)
  const state3CardHandler = createStateCardHandler(2, 500, 1200)
  const state4CardHandler = createStateCardHandler(3)
  const state5CardHandler = createStateCardHandler(4, 300, 800)
  const state6CardHandler = createStateCardHandler(5, 300, 800)

  const assignItemProps = (item, type) => {
    if (item?.state === 'CALIFORNIA') {
      if (type === 'state')
        return {
          ref: state1Ref,
          stateCardHandler: state1CardHandler,
        }

      if (type === 'accordion')
        return {
          ref: accordion1Ref,
        }
    }

    if (item?.state === 'TEXAS') {
      if (type === 'state')
        return {
          ref: state2Ref,
          stateCardHandler: state2CardHandler,
        }

      if (type === 'accordion')
        return {
          ref: accordion2Ref,
        }
    }

    if (item?.state === 'NEW YORK') {
      if (type === 'state')
        return {
          ref: state3Ref,
          stateCardHandler: state3CardHandler,
        }

      if (type === 'accordion')
        return {
          ref: accordion3Ref,
        }
    }

    if (item?.state === 'ARIZONA') {
      if (type === 'state')
        return {
          ref: state4Ref,
          stateCardHandler: state4CardHandler,
        }

      if (type === 'accordion')
        return {
          ref: accordion4Ref,
        }
    }

    if (item?.state === 'GEORGIA') {
      if (type === 'state')
        return {
          ref: state5Ref,
          stateCardHandler: state5CardHandler,
        }

      if (type === 'accordion')
        return {
          ref: accordion5Ref,
        }
    }

    if (item?.state === 'FLORIDA') {
      if (type === 'state')
        return {
          ref: state6Ref,
          stateCardHandler: state6CardHandler,
        }

      if (type === 'accordion')
        return {
          ref: accordion6Ref,
        }
    }

    return {}
  }

  useEffect(() => {
    if (isLoadMore) return
    setMaxHeightContent(`${minHeightContent}px`)
  }, [maxHeightContent, isPhone, isDesktop])

  return (
    <section
      className={clsx([cx.wrapper], wrapperClassName, {
        [cx.load]: isLoadMore,
      })}
    >
      <div className={cx.header}>
        <h2 className={headingClassName}>{heading}</h2>
        <hr />
        <p className={descriptionClassName}>{description}</p>
      </div>

      <div className={cx.featured}>
        {featuredStates?.map((item, key) => {
          const itemProps = assignItemProps(item, 'state')

          return (
            <StateCard
              key={key}
              bg={featuredStatesBG[key]}
              state={item.state}
              abbr={item.abbr}
              {...itemProps}
            />
          )
        })}
      </div>

      <ul
        ref={contentRef}
        className={clsx([cx.items])}
        style={{
          maxHeight: `${maxHeightContent}`,
          minHeight: `${minHeightContent}px`,
        }}
      >
        {items.map((item, key) => {
          const itemProps = assignItemProps(item, 'accordion')

          return (
            <ClientsAccordion
              key={key}
              btnClass={clsx('heading-6')}
              isActive={activeIndex === key}
              activeAccordionHandler={() => activeAccordionHandler(key)}
              state={item.state}
              abbr={item.abbr}
              content={item.content}
              refreshHeight={refreshHeight}
              {...itemProps}
            />
          )
        })}

        <Button
          ghost
          wrapperClassName={clsx([cx.btn])}
          svg={<SvgArrowAlt />}
          text="View More Areas"
          onClick={toggleAccordion}
        />

        <Button
          ghost
          large
          wrapperClassName={cx.btnLess}
          svg={<SvgArrowAlt />}
          text="View Less Areas"
          onClick={toggleAccordion}
        />
      </ul>
    </section>
  )
}

ClientsList.propTypes = {
  wrapperClassName: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  featuredStates: PropTypes.array,
  featuredStatesBG: PropTypes.array,
  items: PropTypes.array,
  headingClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
}

export default ClientsList
