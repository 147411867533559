import { useStaticQuery, graphql } from 'gatsby'

const useTop10CardsMetadata = () => {
  const query = useStaticQuery(graphql`
    query Top10CardsMetadata {
      top10BG: file(relativePath: { eq: "top-10-cards/bg.jpg" }) {
        id
        ...Top10CardsImageOptimize
      }
      top10Video: file(relativePath: { eq: "top-10-cards/video.png" }) {
        id
        ...Top10CardsImageOptimize
      }
      top10Mobile: file(relativePath: { eq: "top-10-cards/mobile.png" }) {
        id
        ...Top10CardsImageOptimize
      }
      top10International: file(
        relativePath: { eq: "top-10-cards/international.png" }
      ) {
        id
        ...Top10CardsImageOptimize
      }
      top10Aix: file(relativePath: { eq: "top-10-cards/aix.png" }) {
        id
        ...Top10CardsImageOptimize
      }
      top10AgentPro: file(relativePath: { eq: "top-10-cards/agent-pro.png" }) {
        id
        ...Top10CardsImageOptimize
      }
      top10SemiCustom: file(
        relativePath: { eq: "top-10-cards/semi-custom.png" }
      ) {
        id
        ...Top10CardsImageOptimize
      }
      top10ImagineStudio: file(
        relativePath: { eq: "top-10-cards/imagine-studio.png" }
      ) {
        id
        ...Top10CardsImageOptimize
      }
      top10TrueCustom: file(
        relativePath: { eq: "top-10-cards/true-custom.png" }
      ) {
        id
        ...Top10CardsImageOptimize
      }
    }

    fragment Top10CardsImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}

export default useTop10CardsMetadata
