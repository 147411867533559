// extracted by mini-css-extract-plugin
export var bg = "ClientsList-module--bg--9c005";
export var btn = "ClientsList-module--btn--6eb67";
export var btnLess = "ClientsList-module--btn-less--5af1a";
export var card = "ClientsList-module--card--cf08d";
export var container = "ClientsList-module--container--22f65";
export var content = "ClientsList-module--content--ede76";
export var featured = "ClientsList-module--featured--828c8";
export var header = "ClientsList-module--header--c92fe";
export var items = "ClientsList-module--items--dceb1";
export var load = "ClientsList-module--load--42219";
export var wrapper = "ClientsList-module--wrapper--85563";