// extracted by mini-css-extract-plugin
export var accent = "Top10Cards-module--accent--7b15f";
export var btn = "Top10Cards-module--btn--e82f1";
export var btnLess = "Top10Cards-module--btn-less--e6ef1";
export var card = "Top10Cards-module--card--8d268";
export var content = "Top10Cards-module--content--b4cfc";
export var header = "Top10Cards-module--header--04458";
export var img = "Top10Cards-module--img--154d7";
export var list = "Top10Cards-module--list--a905d";
export var load = "Top10Cards-module--load--3aec3";
export var wrapper = "Top10Cards-module--wrapper--dc002";