import React from 'react'

export const pageData = {
  title: 'Clients',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: 'Clients',
      url: '/clients/',
    },
  ],
  uri: '/clients/',
  lcf: {
    version: 'LCF05',
    model: 'Natalie',
    title: 'Free Consultation',
    subtitle: 'GET IN TOUCH WITH US FOR A',
    submitLabel: 'I want a stunning website!',
    leadName: 'Consultation: Clients',
    layout: 'A',
  },
}

export const bannerData = {
  heading: 'Some of<br/> Our Valued Clients',
  subheading: 'Real Estate Agents &<br/> Brokers',
}

export const introData = {
  heading: 'Ut enim ad minim veniam',
  subheading: 'Duis aute irure dolor in reprehenderit',
  paragraph:
    'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  alt:
    'The Jills Zeder Group, Quiana Watson, and The Oppenheim Group website screenshots',
}

export const listData = {
  heading: 'Ut enim ad minim veniam',
  description:
    'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  featuredStates: [
    { state: 'CALIFORNIA', abbr: 'CA' },
    { state: 'TEXAS', abbr: 'TX' },
    { state: 'NEW YORK', abbr: 'NY' },
    { state: 'ARIZONA', abbr: 'AZ' },
    { state: 'GEORGIA', abbr: 'GA' },
    { state: 'FLORIDA', abbr: 'FL' },
  ],
  clients: [
    {
      state: 'ALABAMA',
      abbr: 'AL',
      content: [
        {
          website: 'Kellar Realty and Property Management, Inc.',
          city: 'Lorem ipsum<br/> dolor sit amet',
          specialization: 'Lorem ipsum dolor sit amet',
          link: '/',
        },
        {
          website: 'Jenny Leswati & Associates LLC',
          city: 'Chattahoochee Hills, GA',
          specialization: 'Ut enim ad minim veniam',
          link: '/',
        },
        {
          website: 'Premier Properties of the Desert',
          city: 'Lorem ipsum<br/> dolor sit amet',
          specialization: 'Duis aute irure',
          link: '/',
        },
      ],
    },
    {
      state: 'ALASKA',
      abbr: 'AK',
      content: [
        {
          website: 'Kellar Realty and Property Management, Inc.',
          city: 'Lorem ipsum<br/> dolor sit amet',
          specialization: 'Lorem ipsum dolor sit amet',
          link: '/',
        },
        {
          website: 'Jenny Leswati & Associates LLC',
          city: 'Chattahoochee Hills, GA',
          specialization: 'Ut enim ad minim veniam',
          link: '/',
        },
        {
          website: 'Premier Properties of the Desert',
          city: 'Lorem ipsum<br/> dolor sit amet',
          specialization: 'Duis aute irure',
          link: '/',
        },
      ],
    },
    {
      state: 'ARIZONA',
      abbr: 'AZ',
      content: [
        {
          website: 'Kellar Realty and Property Management, Inc.',
          city: 'Lorem ipsum<br/> dolor sit amet',
          specialization: 'Lorem ipsum dolor sit amet',
          link: '/',
        },
        {
          website: 'Jenny Leswati & Associates LLC',
          city: 'Chattahoochee Hills, GA',
          specialization: 'Ut enim ad minim veniam',
          link: '/',
        },
        {
          website: 'Premier Properties of the Desert',
          city: 'Lorem ipsum<br/> dolor sit amet',
          specialization: 'Duis aute irure',
          link: '/',
        },
      ],
    },
    {
      state: 'ARKANSAS',
      abbr: 'AK',
      content: [
        {
          website: 'Kellar Realty and Property Management, Inc.',
          city: 'Lorem ipsum<br/> dolor sit amet',
          specialization: 'Lorem ipsum dolor sit amet',
          link: '/',
        },
        {
          website: 'Jenny Leswati & Associates LLC',
          city: 'Chattahoochee Hills, GA',
          specialization: 'Ut enim ad minim veniam',
          link: '/',
        },
        {
          website: 'Premier Properties of the Desert',
          city: 'Lorem ipsum<br/> dolor sit amet',
          specialization: 'Duis aute irure',
          link: '/',
        },
      ],
    },
    {
      state: 'CALIFORNIA',
      abbr: 'CA',
      content: [
        {
          website: 'Kellar Realty and Property Management, Inc.',
          city: 'Lorem ipsum<br/> dolor sit amet',
          specialization: 'Lorem ipsum dolor sit amet',
          link: '/',
        },
        {
          website: 'Jenny Leswati & Associates LLC',
          city: 'Chattahoochee Hills, GA',
          specialization: 'Ut enim ad minim veniam',
          link: '/',
        },
        {
          website: 'Premier Properties of the Desert',
          city: 'Lorem ipsum<br/> dolor sit amet',
          specialization: 'Duis aute irure',
          link: '/',
        },
      ],
    },
    {
      state: 'COLORADO',
      abbr: 'CO',
      content: [],
    },
    {
      state: 'CONNECTICUT',
      abbr: 'CT',
      content: [],
    },
    {
      state: 'DELAWARE',
      abbr: 'DE',
      content: [],
    },
    {
      state: 'FLORIDA',
      abbr: 'FL',
      content: [
        {
          website: 'Kellar Realty and Property Management, Inc.',
          city: 'Lorem ipsum<br/> dolor sit amet',
          specialization: 'Lorem ipsum dolor sit amet',
          link: '/',
        },
        {
          website: 'Jenny Leswati & Associates LLC',
          city: 'Chattahoochee Hills, GA',
          specialization: 'Ut enim ad minim veniam',
          link: '/',
        },
        {
          website: 'Premier Properties of the Desert',
          city: 'Lorem ipsum<br/> dolor sit amet',
          specialization: 'Duis aute irure',
          link: '/',
        },
      ],
    },
    {
      state: 'GEORGIA',
      abbr: 'GA',
      content: [
        {
          website: 'Kellar Realty and Property Management, Inc.',
          city: 'Lorem ipsum<br/> dolor sit amet',
          specialization: 'Lorem ipsum dolor sit amet',
          link: '/',
        },
        {
          website: 'Jenny Leswati & Associates LLC',
          city: 'Chattahoochee Hills, GA',
          specialization: 'Ut enim ad minim veniam',
          link: '/',
        },
        {
          website: 'Premier Properties of the Desert',
          city: 'Lorem ipsum<br/> dolor sit amet',
          specialization: 'Duis aute irure',
          link: '/',
        },
      ],
    },
    {
      state: 'HAWAII',
      abbr: 'HI',
      content: [],
    },
    {
      state: 'IDAHO',
      abbr: 'ID',
      content: [],
    },
    {
      state: 'ILLINOIS',
      abbr: 'IL',
      content: [],
    },
    {
      state: 'INDIANA',
      abbr: 'IN',
      content: [],
    },
    {
      state: 'IOWA',
      abbr: 'IA',
      content: [],
    },
    {
      state: 'KANSAS',
      abbr: 'KS',
      content: [],
    },
    {
      state: 'KENTUCKY',
      abbr: 'KY',
      content: [],
    },
    {
      state: 'LOUISIANA',
      abbr: 'LA',
      content: [],
    },
    {
      state: 'MAINE',
      abbr: 'ME',
      content: [],
    },
    {
      state: 'MARYLAND',
      abbr: 'MD',
      content: [],
    },
    {
      state: 'MASSACHUSETTS',
      abbr: 'MA',
      content: [],
    },
    {
      state: 'MICHIGAN',
      abbr: 'MI',
      content: [],
    },
    {
      state: 'MINNESOTA',
      abbr: 'MN',
      content: [],
    },
    {
      state: 'MISSISSIPPI',
      abbr: 'MS',
      content: [],
    },
    {
      state: 'MISSOURI',
      abbr: 'MO',
      content: [],
    },
    {
      state: 'MONTANA',
      abbr: 'MT',
      content: [],
    },
    {
      state: 'NEBRASKA',
      abbr: 'NE',
      content: [],
    },
    {
      state: 'NEVADA',
      abbr: 'NV',
      content: [],
    },
    {
      state: 'NEW HAMPSHIRE',
      abbr: 'NH',
      content: [],
    },
    {
      state: 'NEW JERSEY',
      abbr: 'NJ',
      content: [],
    },
    {
      state: 'NEW MEXICO',
      abbr: 'NM',
      content: [],
    },
    {
      state: 'NEW YORK',
      abbr: 'NY',
      content: [
        {
          website: 'Kellar Realty and Property Management, Inc.',
          city: 'Lorem ipsum<br/> dolor sit amet',
          specialization: 'Lorem ipsum dolor sit amet',
          link: '/',
        },
        {
          website: 'Jenny Leswati & Associates LLC',
          city: 'Chattahoochee Hills, GA',
          specialization: 'Ut enim ad minim veniam',
          link: '/',
        },
        {
          website: 'Premier Properties of the Desert',
          city: 'Lorem ipsum<br/> dolor sit amet',
          specialization: 'Duis aute irure',
          link: '/',
        },
      ],
    },
    {
      state: 'NORTH CAROLINA',
      abbr: 'NC',
      content: [],
    },
    {
      state: 'NORTH DAKOTA',
      abbr: 'ND',
      content: [],
    },
    {
      state: 'OHIO',
      abbr: 'OH',
      content: [],
    },
    {
      state: 'OKLAHOMA',
      abbr: 'OK',
      content: [],
    },
    {
      state: 'OREGON',
      abbr: 'OR',
      content: [],
    },
    {
      state: 'PENNSYLVANIA',
      abbr: 'PA',
      content: [],
    },
    {
      state: 'RHODE ISLAND',
      abbr: 'RI',
      content: [],
    },
    {
      state: 'SOUTH CAROLINA',
      abbr: 'SC',
      content: [],
    },
    {
      state: 'SOUTH DAKOTA',
      abbr: 'SD',
      content: [],
    },
    {
      state: 'TENNESSEE',
      abbr: 'TN',
      content: [],
    },
    {
      state: 'TEXAS',
      abbr: 'TX',
      content: [
        {
          website: 'Kellar Realty and Property Management, Inc.',
          city: 'Lorem ipsum<br/> dolor sit amet',
          specialization: 'Lorem ipsum dolor sit amet',
          link: '/',
        },
        {
          website: 'Jenny Leswati & Associates LLC',
          city: 'Chattahoochee Hills, GA',
          specialization: 'Ut enim ad minim veniam',
          link: '/',
        },
        {
          website: 'Premier Properties of the Desert',
          city: 'Lorem ipsum<br/> dolor sit amet',
          specialization: 'Duis aute irure',
          link: '/',
        },
      ],
    },
    {
      state: 'UTAH',
      abbr: 'UT',
      content: [],
    },
    {
      state: 'VERMONT',
      abbr: 'VT',
      content: [],
    },
    {
      state: 'VIRGIN ISLANDS',
      abbr: 'VI',
      content: [],
    },
    {
      state: 'VIRGINIA',
      abbr: 'VA',
      content: [],
    },
    {
      state: 'WASHINGTON',
      abbr: 'WA',
      content: [],
    },
    {
      state: 'WASHINGTON DC',
      abbr: 'DC',
      content: [],
    },
    {
      state: 'WEST VIRGINIA',
      abbr: 'WV',
      content: [],
    },
    {
      state: 'WISCONSIN',
      abbr: 'WI',
      content: [],
    },
    {
      state: 'WYOMING',
      abbr: 'WY',
      content: [],
    },
    {
      state: 'Canada',
      abbr: '',
      content: [],
    },
    {
      state: 'International',
      abbr: '',
      content: [],
    },
  ],
}
